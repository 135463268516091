import PolicyText from "../components/Policy-Components/PolicyText";

const Policypage = () => {
  return (
    <PolicyText
      Logo="/images/Home-Sub-images/TechCampusLogo.webp"
    />
  );
};

export default Policypage;
